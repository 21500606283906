import { Link } from 'gatsby'
import React from 'react'

import './header.scss'
import menuIcon from '../images/baseline_menu_white_48dp.png'
import logo from '../images/logo.png'

const toggleMenu = () => document.querySelector('.nav-menu').classList.toggle('mobile-hidden')

const Header = () => (
  <header className='nav-bar page-header'>
    <div className='nav-flex-mini'>
      <Link to='/' className='nav-bar-logo'>
        <img src={logo} alt='Coach Miller Logo' height='32' width='57' />
      </Link>
      <button type='button' className='nav-burger-btn' onClick={toggleMenu}>
        <img src={menuIcon} alt='menu icon ' height='48' width='48' className='menu-mobile' />
      </button>
    </div>
    <nav className='nav-menu mobile-hidden'>
      <Link to='/#seminars' className='menu-item'>
        Seminars
      </Link>
      <Link to='/#training-plans' className='menu-item'>
        Training Plans
      </Link>
      <Link to='/#about' className='menu-item'>
        About
      </Link>
      <Link to='/#contact' className='menu-item'>
        Contact
      </Link>
    </nav>
  </header>
)

export default Header

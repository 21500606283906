import React from 'react'
import PropTypes from 'prop-types'

import Header from './header'
import './reset.css'
import './layout.scss'

import fbIcon from '../images/facebook-icon.png'
import instgIcon from '../images/instagram-icon.png'

const Layout = ({ children }) => (
  <div className='wrapper'>
    <Header />
    <main className='page-body'>{children}</main>
    <footer className='page-footer'>
      <div className='footer-container'>
        <div className='company-font-size-mb'>
          &copy;
          {` ${new Date().getFullYear()} `}
          Coach Miller Designs
        </div>
        <div>
          <img src={fbIcon} alt='facebook icon' height='24' width='12' className='fb-icon-margin' />
          <img src={instgIcon} alt='instgram icon' height='24' width='24' />
        </div>
      </div>
    </footer>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
